.form {
max-width: 400px;
margin: 0 auto;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
background-color: #f5f5f5;
}

.form label {
display: block;
margin-bottom: 5px;
}

.form input {
display: block;
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
margin-bottom: 15px;
}

.form button {
display: block;
width: 100%;
padding: 10px;
border: none;
border-radius: 5px;
background-color: #007bff;
color: #fff;
cursor: pointer;
transition: all 0.3s ease;
margin-top: 5px;
}

.form button:hover {
background-color: #0062cc;
}