.image-slider {
    position: center;
    width: auto; /* Set the width to your desired value */
  }
  
.slider-images {
  background: #000;
  display: table;
  position: relative;
  overflow: hidden;
  min-width: 400px;
  min-height: 400px;
}

.slider-image img {
  object-fit: cover;
  display: table-cell;
  object-position: center;
  margin: 0 auto;
  max-height: 400px;
  max-width: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.slider-controls {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .prev-button,
  .next-button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .slider-images {
      min-width: 450px;
      min-height: 300px;
    }
  
    .slider-image img {
      max-height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .slider-images {
      min-height: 300px;
      min-width: 300px
    }
  
    .slider-image img {
      max-height: 300px;
    }
  }