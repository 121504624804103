.profile {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .profile h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .profile p {
    margin-bottom: 10px;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .profile ul {
    margin-bottom: 20px;
    padding-left: 0;
  }
  
  .profile ul li {
    list-style: none;
    margin-bottom: 5px;
  }
  
  .profile p:last-child {
    margin-bottom: 0;
  }