@keyframes scale-up {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .scale-up-animation {
    animation: scale-up 0.3s;
  }