.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

.avatar-wrapper {
  float: left;
  margin-right: 10px;
}

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
}

.header {
  margin-bottom: 10px;
}

.restaurant-wrapper {
  margin-bottom: 10px;
}

.medium-image {
  width: 100%;
}

.button-wrapper button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  padding-bottom: 10px;
}

.button-wrapper button i {
  margin-right: 5px;
}

.button-wrapper button span {
  margin-left: 5px;
}

.extra.content {
  margin-top: 10px;
}