.uploaded-image-div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px; /* Adjust the desired height */
    margin-bottom: 10px; /* Add spacing between images if needed */
  }

.uploaded-image-img {
    object-fit: cover;
    width: 150px;
    height: 150px; /* Adjust the desired height */
    margin-bottom: 10px; /* Add spacing between images if needed */
}

.cropped-image {
    width: 200px; /* Desired width of the cropped image */
    height: 200px; /* Desired height of the cropped image */
    object-fit: cover;
    object-position: 25% 25%; 
  }